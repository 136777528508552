﻿// The Rate this App jQuery plugin makes it very easy to add rating and feedback functionality to your HTML based application.
// This plugin requires jQuery 1.7+. It's been tested with IE9, IE10, Safari, Chrome, FireFox, and Opera. It includes support for various screen sizes via CSS3 media queries.
// Examples of usage can be found at http://10.27.216.145/ratethisapp/.
// The plugin was originally created by Daniel Mohl in April 2013, but has had many additional contributors including a significant portion 
// (the bandwidth calculation) by Kevin Hacken.

// Create a closure to protect the global namespace. Also, ensure that $ and undefined refer to the expected things.
; (function ($, undefined) {

    var _animationTime = 500,
		$overlay;

    // Create the main object literal that will hold the majority of our methods and properties.
    var methods = {

        version: "0.9.5.0",

        // TODO: Move "./images/09-chat-2.png" from the templateHtml into our CSS file .

        // Define the default options for the plugin
        defaultOptions: {
            // Specifies the current user's alias, so that we can capture user related information along with the rating and comment.
            userAlias: null,

            // Allows calls to GET/POST information from the common rating repository.
            useCentralRatingRepository: true,

            // Determines the URI for the central rating repository (if useCentralRatingRepository is false, this will never be used).
            serviceUri: "https://ratethisapp.deloitte.com/api/ratethisapp",

            // The API Key used for calls to the backend service. This will not likely need to be changed, unless a custom backend service is used.
            apiKey: "386aa09a-5de9-4b97-b61b-2998fe2ee921",

            // Determines the element to which the rate this app template HTML will be appended.
            appendTo: "body",

            // Specifies the name of the CSS class that should be used onhover over one of the stars.
            starHoverClassName: "dra-rating-image-yellow-star",

            // Specifies the name of the CSS class that should be used for half stars.
            halfStarHoverClassName: "dra-rating-image-yellow-half-star",

            // Specifies the currently selected rating value and allows a default to be provided
            ratingValue: null,

            // Specifies the initial value that should be placed in the comment textarea element
            comment: null,

            // Determines if the summary template should display after submission of the rating information
            showSummaryOnSubmit: true,

            // Determines if the Location Selection dropdown should be shown
            showLocationSelection: false,

            // Holds the selected location information
            locationSelection: null,

            // Set to true to make the selection of a rating required. Set to false if rating selection should not be required.
            forceRatingSelection: true,

            // Text to display when the submit button is clicked.
            savingTextDisplay: "Saving...",

            // Can be used to specify a different unique ID for the widget container DIV. It's really only useful if you wish to have the widet on the same page multiple times.
            uniqueId: "deloitteRateThisApp",

            // Set to true to clear the comment textarea after submit. Set to false if the previous comment should not be cleared.
            clearCommentsAfterSubmit: true,

            // Set to true to clear the comment textarea after cancel. Set to false if the previous comment should not be cleared.
            clearCommentsAfterCancel: true,

            // Sets the path to the images used for bandwidth calculations. 
            speedTestImagePath: "./speedTestImages/",

            // Enables the calculation of bandwidth information. 
            enableBandwidthCalculation: true,

            // Sets up the default markup for the summary view.
            summaryTemplateHtml:
				'<div class="dra-average-rating-label dra-font dra-center-text"> ' +
				'	Here\'s how others have rated this app. ' +
				'</div> ' +
				'<div class="dra-ratings-container"> ' +
				'	<ul class="dra-average-rating"> ' +
				'		<li class="rating-selection" data-rating="1"/> ' +
				'		<li class="rating-selection" data-rating="2"/> ' +
				'		<li class="rating-selection" data-rating="3"/> ' +
				'		<li class="rating-selection" data-rating="4"/> ' +
				'		<li class="rating-selection" data-rating="5"/> ' +
				'	</ul> ' +
				'</div> ' +
				'<div> ' +
				'	<a> ' +
				'		<div class="dra-close-button dra-button dra-blue-gradient dra-cursor-hover dra-align-left dra-thin-font"> ' +
				'			Close ' +
				'		</div> ' +
				'	</a> ' +
				'</div> ',

            // Specifies the default markup for the main rating selection view
            templateHtml:
                '<div class="hideratethisapp dra-main-container" id="{uniqueId}" tabindex="-1"> ' +
                
                '    <table class="dra-body"> ' +
                '<tr> ' +
                '<td class="TileProp" style="background-color:black;"><img src="./assets/img/Frame222.png" style="height:80%;"/> </td>'+
                '<td> ' +
                '<span class="dra-close-button-new icon-Cross_close_SPECIAL dra-align-right"></span> ' +
                '        <div class="dra-feedback-label dra-font dra-label"> ' +
                '           <div> Select a rating to let us know how you </div>'+
                '<div> liked the application experience: </div>' +
               
                '        </div> ' +
				'        <div class="dra-required-text dra-rating-required dra-align-right hideratethisapp" style="margin-top:25px;margin-right:100px;font-family:Open Sans;">Required</div> ' +
                '        <div class="dra-ratings-container"> ' +
                '            <ul class="dra-rating"> ' +
                '                <li><a class="dra-rating-selection" data-rating="1" href="#">I do not like this. Please stop designing apps like this.</a></li> ' +
                '                <li><a class="dra-rating-selection" data-rating="2" href="#">Nothing too new or interesting.</a></li> ' +
                '                <li><a class="dra-rating-selection" data-rating="3" href="#">Not bad, I like it.</a></li> ' +
                '                <li><a class="dra-rating-selection" data-rating="4" href="#">I would like to see more of this.</a></li> ' +
                '                <li><a class="dra-rating-selection" data-rating="5" href="#">This is excellent! Build everything like this!</a></li> ' +
                '            </ul> ' +
                '        </div> ' +
				'        <div class="dra-dropdown dra-cursor-hover"> ' +
				'            <a class="dra-dropdown-display">Select your location...</a> ' +
				'            <ul class="hideratethisapp"> ' +
			    '                <li>I\'m at a Deloitte office in the US.</li>' +
			    '                <li>I\'m at a Deloitte office in India.</li>' +
			    '                <li>I\'m at a Deloitte office in some other country.</li>' +
			    '                <li>I\'m at a client\'s office.</li>' +
			    '                <li>I\'m at some other location.</li>' +
                '            </ul>' +
				'        </div> ' +
                '        <div class="dra-feedback-label dra-font dra-label"> ' +
                '            We appreciate your feedback. What did you think? ' +
                '        </div> ' +
                '        <div> ' +
                '            <textarea placeholder="Share your thoughts" class="dra-rating-feedback dra-feedback-comment" type="text" maxlength="250"></textarea> ' +
                '<div> ' +
                '       <div style="font-family: Open Sans;width: 40%;float: right;text-align: right;" id="textarea_remainingcount">0/250 characters limit</div> ' +
                '       <div class="dra-required-text dra-comment-required hideratethisapp" style="margin-left:23px;font-family: Open Sans;width: 50%;float: left;">Please enter at least 5 characters</div> '+
                '       </div> ' +
                '</div> ' +
                '        <div style="padding-top:35px;padding-bottom:55px;"> ' +
                '            <a> ' +
                '                <div class="dra-sendfeedback-button dra-sendfeedback-button-text dra-align-right"> ' +
                '                    Send feedback ' +
                '                </div> ' +
                '            </a> ' +
                '            <a> ' +
                '                <div class="dra-cancel-button-new dra-cancel-button-new-text dra-align-right"> ' +
                '                    Cancel ' +
                '                </div> ' +
                '            </a> ' +
                '        </div> ' +
                '</td>' +
                '</tr> ' +
                
                '    </table> ' +
                '</div>',

            // Allows a custom callback to be estaplished for when the widget is shown
            show: null,

            // Allows a custom callback to be estaplished for when the widget is hidden
            hide: null,

            // Allows a custom callback to be estaplished for when the cancel button is clicked
            cancel: null,

            // Allows a custom callback to be estaplished for when the rating and/or comment information is changed.
            change: null,

            // Allows a custom callback to be estaplished for when the submit button is clicked
            submit: null
        },

        // Specifies an object literal that will be return when the change or submit event occurs. This is also sent to the backend services. 
        submissionData: {
            rating: 0,
            comment: "",
            locationSelection: "",
            userAlias: "",
            widgetVersion: "",
            bandwidth: "",
            timeZoneOffsetFromUtc: (new Date().getTimezoneOffset() / 60) * (-1),
            url: document.URL,
            userAgent: navigator.userAgent,
        },

        // Defines a "private" property to hold the average rating information that will be displayed on the summary page.
        // While these types of things aren't really private in the OOP sense, we use a convention of an underscore as a prefix to convey the intention of privacy.
        _averageRatingValue: 0,

        // Defines a "private" property used to hold the initial rating value. This is used to reset the rating value in the case of the cancel button being clicked. 
        _initialRatingValue: 0,

        // Defines a "private" property used to hold the jQuery object associated with the element that is clicked to launch the widget.
        _ownerElement: null,

        // Defines a "private" method that is called to determine the user's bandwidth.
        _calculateBandwidth: function () {
            // We only do the bandwidth calculation if the enableBandwidthCalculation option is true and if we haven't already previously calculated the bandwidth. 
            if (methods.options.enableBandwidthCalculation && !methods.submissionData.bandwidth) {
                // Kick off the test asynchornously with the help of setTimeout.
                setTimeout(function () {
                    // Create a new instance of SpeedTest with appropriate options specified
                    var speedTest = new SpeedTest({ imagePath: methods.options.speedTestImagePath, threshold: 2 });

                    // Kick off the speed test and setup a callback for when it finishes.
                    speedTest.speedTest(function (returnValue) {
                        methods.submissionData.bandwidth = returnValue.speedMsg;
                    });
                }, 100);
            }
        },

        // Define a "private" method to act as a facade to the typical jQuery.ajax usage. We also add to the request header by including the API Key.
        _ajaxFacade: function (type, urlPath, dataToSend, successCallback, errorCallback) {
            $.ajax({
                url: methods.options.serviceUri + urlPath,
                type: type,
                data: dataToSend,
                headers: { "X-ApiKey": methods.options.apiKey },
                error: function (jqXHR, textStatus, errorThrow) {
                    if (errorCallback) {
                        errorCallback(jqXHR, textStatus, errorThrow);
                    }
                },
                success: function (data) {
                    if (successCallback) {
                        successCallback(data);
                    }
                }
            });
        },

        // Define a "private" method that is used to initialize the selected rating value and/or comment text. 
        _initDefaultValues: function () {
            // This is primarily in place to allow multiple instances of the control to be hosted on the same page. 
            // In this scenario, each instance should be given a Unique ID as part of the provided options when the widget is initialized.
            var $uniqueId = $("#" + methods.options.uniqueId);
            $uniqueId.find(".dra-rating-selection").removeClass().addClass("dra-rating-selection");
            $('#textarea_remainingcount').html("0/250 characters limit");
            // Reset ratingValue to the initial rating value
            methods.options.ratingValue = 0;
            var $widget = $("#" + methods.options.uniqueId);
                var $ratingInfobox = $widget.find(".dra-rating-info");
                // Add the text to the rating info box
                $ratingInfobox
                    .animate({ opacity: 1 }, _animationTime);
                if (methods.options.ratingValue==0) {$ratingInfobox.html("")}
            // Set default rating value if one was provided
            if (methods.options.ratingValue) {
                methods._highlightRatings(methods.options.ratingValue, ".dra-rating li a");
                if (methods.options.ratingValue==1) {$ratingInfobox.html("I do not like this. Please stop designing apps like this.")}
                if (methods.options.ratingValue==2) {$ratingInfobox.html("Nothing too new or interesting.")}
                if (methods.options.ratingValue==3) {$ratingInfobox.html("Not bad, I like it.")}
                if (methods.options.ratingValue==4) {$ratingInfobox.html("I would like to see more of this.")}
                if (methods.options.ratingValue==5) {$ratingInfobox.html("This is excellent! Build everything like this!")}
            }

            // Set default feedback comment if one was provided
            if (methods.options.comment) {
                $uniqueId.find(".dra-feedback-comment").val(methods.options.comment);
            }

            // Determine if the location selection dropdown should be displayed. By default it is not displayed.
            if (!methods.options.showLocationSelection) {
                $uniqueId.find("div.dra-dropdown").hide();
            } else if (methods.options.locationSelection) {
                $uniqueId.find(".dra-dropdown-display").html(methods.options.locationSelection);
            }
        },

        // Sets up the submit event handlers for the widget. This is done in part to reduce the size of the _initEventHandlers method.
        _initSubmitEventHandlers: function () {
            // TODO: Add support to submit with the enter key.
            var $submitButton = $(".dra-sendfeedback-button");

            // Setup the send button click event.
            $submitButton.off("click").on("click", function (e) {
                methods.submit(e);
            });

            return $submitButton;
        },

        // Sets up all the event handlers for the widget.
        _initEventHandlers: function ($this) {
            var $widget = $("#" + methods.options.uniqueId);
            // Setup the onclick for the specified owner element
            $this.off("click").on("click", function (event) {
                methods.show(event, $this);
            });

            // Setup event for feedback comment keypress
            $(document).on("keyup", ".dra-feedback-comment", function (event) {
                methods.options.comment = $(this).val();
                $widget.find(".dra-comment-required").hide();
                $('#textarea_remainingcount').html($(this).val().length+"/250 characters limit");
                methods.change(event);
            });

            // Add rating information box after rating stars if it hasn't already been added.
            if ($widget.find(".dra-rating-info").length === 0) {
                $("<div class='dra-font'/>")
					.addClass("dra-rating-info")
					.css("opacity", 0)
					.insertAfter($widget.find(".dra-rating"));
            }

            // Setup up the hover event for the rating stars.
            var $ratingInfobox = $widget.find(".dra-rating-info");
            $widget.find(".dra-rating li a").off("hover").hover(function () {

                // Add the text to the rating info box
                $ratingInfobox
                    .html($(this).html())
                    .stop()
                    .animate({ opacity: 1 }, _animationTime);

                methods._highlightRatings($(this).data("rating"), ".dra-rating li a");
            }, function () {
                // Fade out the rating information box
                if (methods.options.ratingValue==0){
                $ratingInfobox
                    .stop()
                    .animate({ opacity: 0 }, _animationTime);
                }
                // Remove the class that causes the stars to show as selected.
                $widget.find(".dra-rating li a").removeClass(methods.options.starHoverClassName);

                // If the ratingValue has been sent, show the appropriate number of starts
                if (methods.options.ratingValue) {
                    methods._highlightRatings(methods.options.ratingValue, ".dra-rating li a");
                    if (methods.options.ratingValue==1) {$ratingInfobox.html("I do not like this. Please stop designing apps like this.")}
                    if (methods.options.ratingValue==2) {$ratingInfobox.html("Nothing too new or interesting.")}
                    if (methods.options.ratingValue==3) {$ratingInfobox.html("Not bad, I like it.")}
                    if (methods.options.ratingValue==4) {$ratingInfobox.html("I would like to see more of this.")}
                    if (methods.options.ratingValue==5) {$ratingInfobox.html("This is excellent! Build everything like this!")}
                    //alert($widget.find(".dra-rating li a").html());
                    //alert($(this).html());
                }
            });

            // This sets up the location selecction dropdown. We only do this work if showLocationSelection option is true.
            if (methods.options.showLocationSelection) {
                var $locationDropdown = $widget.find("div.dra-dropdown");
                // Setup the click event for the location selection dropdown
                $locationDropdown.off("click").on("click", function () {
                    $(this).find("ul").toggle();
                    return false;
                });

                // Setup the click event for each li element.
                $locationDropdown.find("li").off("click").on("click", function () {
                    methods.options.locationSelection = $(this).html();
                    $locationDropdown.find(".dra-dropdown-display").html(methods.options.locationSelection);
                });

                // Setup an event that will cause the location selection dropdown to close if the user clicks anywhere else on the widget.
                $widget.off("click").on("click", function () {
                    $locationDropdown.find("ul").hide();
                });
            }

            // Setup the rating selection click event.
            $widget.find(".dra-rating li a").off("click").on("click", function (e) {
                methods.options.ratingValue = $(this).data("rating");
                $widget.find(".dra-rating li a").removeClass(methods.options.starHoverClassName);
                methods._highlightRatings(methods.options.ratingValue, ".dra-rating li a")
                methods.change(e);
                $(".dra-rating-required").hide();
                return false;
            });

            // Call the method to initialize the submit button event handlers.
            methods._initSubmitEventHandlers();

            // Setup the cancel button click event. 
            $(".dra-cancel-button-new").off("click").on("click", function (e) {
                methods.cancel(e);
            });
            $(".dra-close-button-new").off("click").on("click", function (e) {
                methods.cancel(e);
            });
        },

        // This is a "private" method that is used to show the appropriate number of selected stars.
        _highlightRatings: function (providedRating, selector) {
            $(selector).each(function () {
                var $this = $(this);
                var $rating = $this.data("rating");
                if ($rating <= providedRating) {
                    $this.addClass(methods.options.starHoverClassName);
                } else if ((providedRating > $rating - 1) && providedRating < $rating) {
                    $this.addClass(methods.options.halfStarHoverClassName);
                }
            });
        },

        // We save the entire methods object as a data element on the owner element. This allows us to maintain state on the client side 
        // without having to resort to some out of proc approach.
        _saveOptions: function () {
            methods._ownerElement.data("deloitteRateThisAppOptions", $.extend({}, methods));
        },

        // This is the main function that is called when the widget is initialized.
        init: function (customOptions, $this) {
            // Override default options if required
            methods.options = $.extend({}, methods.defaultOptions, customOptions);

            // Set the initial rating value 
            methods._initialRatingValue = methods.options.ratingValue;

            // Sets the owner element
            methods._ownerElement = $this || $(this);

            // Save the object as it exists right now.
            methods._saveOptions();

            // Load the template HTML and append it to the body of the document (if it hasn't already been appended)
            if (!$("#" + methods.options.uniqueId).length) {
                $(methods.options.appendTo).append(methods.options.templateHtml.replace("{uniqueId}", methods.options.uniqueId));
            }

            // Call a method to setup all the event handlers.			
            methods._initEventHandlers(methods._ownerElement);

            return this;
        },

        // This is a "private" method that sets up the summary display.
        _setupSummaryView: function (averageRating) {
            var $widget = $("#" + methods.options.uniqueId);
            // We try to reduce the DOM elements that will be searched in order to improve perf. DOM traversal is often the most expensive operation that we have to perform.
            $widget.find(".dra-body").stop().hide().html(methods.options.summaryTemplateHtml).fadeIn();
            methods._highlightRatings(averageRating, ".dra-average-rating li");

            var $closeButton = $widget.find(".dra-close-button");

            $closeButton.focus();

            $closeButton.on("click", function (e) {
                methods.destroy(e);
            });
        },

        // This is a "private" method that is called to show the summary view
        _showSummary: function (callback) {
            methods._setupSummaryView(methods._averageRatingValue || methods.options.ratingValue || 0);

            return this;
        },

        // This method calls the hide method removes the markup that was added to the page.
        destroy: function () {
            methods.hide();
            // We wait 300 ms before removing the elements in order to have time to allow the hide method to finish it's animation stuff.
            setTimeout(function () {
                $("#" + methods.options.uniqueId).remove();
            }, 300);

            return this;
        },

        // This is a provide method that is called when the widget is openned in order to pre-fetch the average rating value. 
        // It pre-fetches in order to reduce the time it takes to display to the end sure.
        _getAverageRating: function () {
            // The call to GetAverageRating is only done if the useCentralRatingRepository option is set to true.
            if (methods.options.useCentralRatingRepository) {
                methods._ajaxFacade("GET", "/GetAverageRating", "", function (averageRating) {
                    methods._averageRatingValue = averageRating;
                });
            } else {
                // if useCentralRatingRepository is false, then we simply set the average rating value to the current value selected by the user.
                methods._averageRatingValue = methods.options.ratingValue || 0;
            }
        },

        // This method is used to show the widget
        show: function (event, $ownerEl) {
            // If the owner element was passed in, then we restore the methods object from the data element on that owner element. 
            // $ownerEl will only be passed in if the initialization has previously been done.
            if ($ownerEl) {
                methods = jQuery.extend({}, $ownerEl.data("deloitteRateThisAppOptions"));
            }

            var $widget = $("#" + methods.options.uniqueId);
            // If an element with the specified uniqueId doesn't exist in the DOM, then we reinitialize and assign to $widget to the newly created element.
            if (!$widget.length) {
                methods.init(methods.options, methods._ownerElement);
                $widget = $("#" + methods.options.uniqueId);
            }

            // Kick off the asynchronous request to get the average rating for the app. 
            // This happens on show in order to pre-fetch the data and make the user experience faster.
            methods._getAverageRating();

            // Kick off the asynchronous request to determine the bandwidth for the user. 
            // The theshold is 2 seconds, so anything after that will cause the bandwidth value to be defaulted to null. 
            // This happens on show in order to pre-fetch the data and make the user experience faster.
            methods._calculateBandwidth();

            // Add a div to the designated appendTo element. This provides the "grayed out" background effect.
            $overlay = $('<div class="dra-overlay" />').appendTo($(methods.options.appendTo));

            // Call the method to initialize the default values
            methods._initDefaultValues();

            // Use a jQuery animation to make the display of the widget more interesting.
            $widget.slideDown();

            // Wait for 300ms for the animation to complete, then add focus to the comment textarea.
            setTimeout(function () {
                $("#" + methods.options.uniqueId).find(".dra-rating-feedback").focus();
            }, 300);

            // If the show callback was provided, trigger that callback and pass the current list of options as the second argument.
            if (methods.options.show) {
                methods.options.show(event, methods.options);
            }
            return this;
        },

        // Hides the widget. This is also called on cancel /ordestroy
        hide: function (event) {
            // Use a jQuery animation to make the hiding of the widget more interesting.
            $("#" + methods.options.uniqueId).slideUp();
            // Wait 400ms to allow the slideup to finish, then remove the overlay background. This makes for a nice, but subtle effect.
            setTimeout(function () {
                if ($overlay) {
                    $overlay.remove();
                }
            }, 400);

            // If the hide callback was specified, trigger that callback and pass the current options as the second param.
            if (methods.options.hide) {
                methods.options.hide(event, methods.options);
            }
            return this;
        },

        // This is a "private" method that handles everything that needs to occur after a submit has been done. 
        _afterSubmit: function ($sendButton, saveButtonPreviousHtml, event) {
            // If the clearCommentsAfterSubmit option is true, then we clear the comment box and the methods.options.comment value.
            if (methods.options.clearCommentsAfterSubmit) {
                methods.options.comment = "";
                $("#" + methods.options.uniqueId).find(".dra-feedback-comment").val('');
            }

            // Call the _saveOptions method to save the latest information back to the data attribute on the owner element
            methods._saveOptions();

            // If showSummaryOnSubmit is true, then we call the _showSummary method with a callback to set the submit button text back to the initial text.
            if (methods.options.showSummaryOnSubmit) {
                methods._showSummary(function () {
                    methods._initSubmitEventHandlers().html(saveButtonPreviousHtml);
                });
            } else {
                // If showSummaryOnSubmit is false, then we reset the submit button text and call destroy to hide the widget and 
                // remove all dynamically generated markup from the DOM.
                methods._initSubmitEventHandlers().html(saveButtonPreviousHtml);
                methods.destroy(event);
            }
        },

        // Sets the SubmissionData object values 
        _setSubmissionData: function () {
            methods.submissionData.rating = methods.options.ratingValue;
            methods.submissionData.comment = methods.options.comment;
            methods.submissionData.locationSelection = methods.options.locationSelection;
            methods.submissionData.widgetVersion = methods.version;
            methods.submissionData.userAlias = methods.options.userAlias;
            methods.submissionData.url = document.URL;
        },

        // This method is called when the submit button is clicked.
        submit: function (event) {
            var $widget = $("#" + methods.options.uniqueId);
            // If the forceRatingSelection option is set to true, then validate that a rating value has been selected. If not, don't allow the 
            // submit to continue and show the "required" label.
            if (methods.options.forceRatingSelection && (!methods.options.ratingValue || methods.options.ratingValue <= 0 || methods.options.ratingValue > 5) && methods.options.comment!=null && methods.options.comment.length!=0 && methods.options.comment.length<5) {
                $widget.find(".dra-rating-required").show();
                $widget.find(".dra-comment-required").show();
                return false;
            }
            if (methods.options.forceRatingSelection && (!methods.options.ratingValue || methods.options.ratingValue <= 0 || methods.options.ratingValue > 5)) {
                $widget.find(".dra-rating-required").show();
                return false;
            }
            if(methods.options.comment!=null && methods.options.comment.length!=0 && methods.options.comment.length<5){
                $widget.find(".dra-comment-required").show();
                return false;
            }
            // Find the send button and assign it to a variable so that we only have to look it up in the DOM once.
            var $sendButton = $widget.find(".dra-sendfeedback-button");

            // Get the current text of the save button so that we can reset it after submission is complete.
            var saveButtonPreviousHtml = $sendButton.html();

            // Remove the click event from the sendButton and change the text to the savingTextDispaly option value.
            $sendButton.off("click").html(methods.options.savingTextDisplay);

            // Call the _setSubmissionData method to make sure the submissionData object is appropriately populated with the latest values.
            methods._setSubmissionData();

            // If the submit callback is defined, call it and pass the submissionData.
            if (methods.options.submit) {
                methods.options.submit(event, methods.submissionData);
            }

            // Call the method to persist the information to the central repository (if the useCentralRatingRepository option is set to true).
            // After the post is complete or if useCnetralRatingRepository is false, call the _afterSubmit function.
            if (methods.options.useCentralRatingRepository) {
                methods._ajaxFacade("POST", "/SubmitRating", methods.submissionData, function () {
                    methods._afterSubmit($sendButton, saveButtonPreviousHtml, event);
                });
            } else {
                methods._afterSubmit($sendButton, saveButtonPreviousHtml, event);
            }
            return this;
        },

        // This method is called whenever a change occurs for selected rating or the comment textarea.
        change: function (event) {
            // If the change callback is defined, call it and pass the current options.
            if (methods.options.change) {
                methods.options.change(event, methods.options);
            }
            return this;
        },

        // This event occurs when the cancel button is clicked.
        cancel: function (event) {
            // If a callback is defined for cancel, call it and pass the current options as the second parameter.
            if (methods.options.cancel) {
                methods.options.cancel(event, methods.options);
            }

            // Reset ratingValue to the initial rating value since cancel was clicked
            methods.options.ratingValue = methods._initialRatingValue;

            // If the clearCommentsAfterCancel option is true, clear the value in the textarea and in the options object.
            if (methods.options.clearCommentsAfterCancel) {
                methods.options.comment = "";
                $("#" + methods.options.uniqueId).find(".dra-feedback-comment").val('');
            }
            $("#" + methods.options.uniqueId).find(".dra-comment-required").hide();
            $("#" + methods.options.uniqueId).find(".dra-rating-required").hide();
            // Call the hide method.
            methods.hide(event);
            return this;
        },
    };

    // Make this a jQuery pluging
    $.fn.rateThisApp = function (method) {

        // If the methods object has a method with the passed in name, call it		
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            // Else call the init function (if the method is an object) and pass the arguments value
            return methods.init.apply(this, arguments);
        } else {
            // If all else fails, throw an error.
            $.error('Method ' + method + ' does not exist on jQuery.rateThisApp');
        }
    };

    // Define the SpeedTest object and allow options to be passed in.
    function SpeedTest(opts) {

        // Assign this to self so that we can ensure the ability to reference this object regardless of the context of this at any point in time.
        var self = this;

        // Set the threshold to 2 by default
        self.threshold = 2;

        // If options are provided, override the default options appropriately
        if (opts) {
            // This overrides the threshold
            self.threshold = opts.threshold;
            // This overrides the path to the images used to determine bandwidth.
            self.imagePath = opts.imagePath;
        }

        // This is an internal function that is used to generate a random string to ensure that the images used for the speed test are not cached to the local browser.
        // Within the Rate this App widget, a convention is used to convey meaning. A function name that starts with underscore is considered provide, while no underscore indicates public.
        self._randomString = function (size) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < size; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        };

        // This is the main public function that is called to initiate the speed test. 
        self.speedTest = function (complete) {
            var loadTime = 0;
            var threshold = self.threshold;

            var qHolder = $({});
            qHolder.queue("fx", []);

            var path = self.imagePath;

            var files = [
				{ file: "myimage250.jpeg", size: 217167 },
				{ file: "myimage350.jpeg", size: 425160 },
				{ file: "myimage500.jpeg", size: 867124 },
				{ file: "myimage750.jpeg", size: 1950290 },
				{ file: "myimage1000.jpeg", size: 3466469 },
				{ file: "myimage1500.jpeg", size: 7798368 },
				{ file: "myimage2000.jpeg", size: 13862599 },
				{ file: "myimage2500.jpeg", size: 21659907 },
				{ file: "myimage3000.jpeg", size: 31189281 }
            ];

            qHolder.queue(function () {
                self._downloadTimeTest(path + "onepixel.jpeg", function (testTime) {
                    loadTime = testTime;
                    qHolder.dequeue();
                });
            });

            qHolder.queue(function () {
                self._downloadTimeTest(path + files[0].file, function (testTime) {
                    var testFile = files[0];
                    var targetSize = (files[0].size * threshold) / testTime;
                    if (testTime > threshold) {
                        self._finalResults(testTime, loadTime, testFile.size);
                    } else {
                        for (var x = 0; x < files.length; x++) {
                            testFile = files[x];
                            if (files[x].size >= targetSize) {
                                break;
                            }
                        }

                        self._downloadTimeTest(path + testFile.file, function (testTime) {
                            var ret = self._finalResults(testTime, loadTime, testFile.size);
                            if (complete) {
                                complete(ret);
                            }
                        });
                    }

                    qHolder.dequeue();
                });
            });
        };

        // Calculates and returns the bandwidth information
        self._finalResults = function (time, loadTime, size) {
            var msg = "";
            var totalSize = size * 2;
            var adjTime = time - loadTime;

            var ret = { date: new Date(), ping: 0, mps: 0, kps: 0, msg: "" }

            if (adjTime <= 0) {
                msg = "Error running test."
            } else {
                var bitsLoaded = totalSize * 8;
                var bps = bitsLoaded / adjTime;
                var kps = bps / 1024;
                var mps = kps / 1024;

                var bandwidth = mps.toFixed(2)
                var ping = (loadTime * 1000).toFixed(0);

                var speedMsg = (mps >= 1) ? mps.toFixed(2) + " MB/sec" : kps.toFixed(2) + " KB/sec";
                msg = new Date() + " Ping: " + ping + "     " + speedMsg;

                ret.date = new Date();
                ret.ping = ping;
                ret.mps = mps.toFixed(2);
                ret.kps = kps.toFixed(2);
                ret.msg = msg;
                ret.speedMsg = speedMsg;
            }

            return ret;
        };

        // This function handles the brunt of the work associated with calculating the bandwidth info for the user.
        self._downloadTimeTest = function (file, callback) {
            var returnTime = 0;
            var url1 = file + "?t=" + self._randomString(20);
            var url2 = file + "?t=" + self._randomString(20);

            var img1 = $("<img />");
            var img2 = $("<img />");

            var qHolder = $({});
            qHolder.queue("fx", []);

            img1.load(function () {
                qHolder.dequeue();
            });

            img2.load(function () {
                qHolder.dequeue();
            });

            var start = new Date();

            qHolder.queue(function () {
                img1.attr("src", url1);
                img2.attr("src", url2);
            });

            qHolder.queue(function () {
                //Placeholder to allow for the two threads to sync
            });

            qHolder.queue(function () {
                returnTime = (new Date() - start) / 1000;
                callback(returnTime);
                qHolder.dequeue();
            });
        };

        return self;
    };
})(jQuery);

